<template>
  <div>
    <b-row class="px-1">
      <b-col cols="12" class="mb-2 pt-1">
        <b-row class="d-flex justify-content-between align-items-center">
          <b-col cols="6">
            <a class="navbar-brand" @click="navigateToHome">
              <!-- :height="height" -->
              <b-img
                class="adjustWidth"
                :src="require('@/assets/images/logo/logo_mtca.png')"
                alt="logo"
            /></a>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <h6 class="text-justify txt">
              Plateforme des opérateurs touristiques du Bénin
            </h6>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-card class="text-center">
          <b-spinner v-if="loading" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BSpinner,
  BRow,
  BCol,
  BImg,
  BLink,
} from "bootstrap-vue";
import DemandesSection from "@/components/HomeComponents/DemandesSection.vue";
import { mapState, mapActions } from "vuex";
import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BButton,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BImg,
    BLink,
    ToastificationContent,

    DemandesSection,
  },
  data() {
    return {
      ssoUrlCallback: "http://137.255.9.61:27800/api/login/sso",
      loading: true,
    };
  },

  computed: {
    // ...mapState("auth", {
    //   isAuthenticated: "isAuthenticated",
    // }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.validate_login();
      },
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },

  methods: {
    navigateToHome() {
      window.location = "/";
    },
    // login_sso() {
    //   window.location.href =
    //     "https://pprodofficial.service-public.bj/official/login?client_id=mainfo-mtca&redirect_uri=" +
    //     this.ssoUrlCallback +
    //     "&response_type=code&scope=openid&authError=true";
    // },
    validate_login() {
      const { code } = this.$route.query;
      console.log("code::: ", code);
      // send to back_end
      this.$store
        .dispatch("utils/sso_send_code_to_log", { code })
        .then((response) => {
          console.log("response::: ", response);
          if (response.status == 200 && response.data && response.data.data) {
            const { user, token } = response.data.data;
            console.log("user, token::: ", user, token);
            localstorageService.setJwtToken(token);
            if (user && user.roles) {
              const permissions = [
                ...initialAbility,
                ...(user.permissions || [])
                  .map((e) => ({ subject: e.resource, action: e.action }))
                  .filter((e) => e.subject !== "all"),
              ];
              console.log("permissions::: ", permissions);
              delete user.permissions;
              localstorageService.setUserData({
                ...user,
                ability: permissions,
              });
            }
            window.location = "/";
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "La connexion n'a pas abouti. Veuillez réessayer.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            // setTimeout(() => {
            //   window.location.href =
            //     "https://pprodofficial.service-public.bj/official/login?client_id=mainfo-mtca&redirect_uri=" +
            //     this.ssoUrlCallback +
            //     "&response_type=code&scope=openid&authError=true";
            // }, 15000);
          }
        })
        .catch((error) => {
          console.log("error::: ⭕️", error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: error.message || "Process error",
          //     icon: "AlertTriangleIcon",
          //     variant: "danger",
          //   },
          // });
        });
    },
  },
};
</script>
<style scoped>
.adjustWidth {
  width: 135px;
  height: 48px;
}

/* MEDIA QUERIES */
@media (min-width: 1200px) {
  .adjustWidth {
    width: 185px;
    height: 60px;
  }
}

.txt {
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
</style>
